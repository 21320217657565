var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.pc)?_c('div',{staticClass:"school"},[_c('div',{staticClass:"head"},[_c('baseheadTwo',{attrs:{"dataList":_vm.dataList.modular,"banner":_vm.banner,"title":_vm.$route.query.name,"currentIndex":_vm.currentIndex},on:{"tabs":_vm.tabs}})],1),_c('div',{staticClass:"content"},[(_vm.dialogVisible)?_c('div',{staticClass:"shade"}):_vm._e(),_c('div',{staticClass:"content_left"},[_c('el-menu',{staticClass:"el-menu-vertical-demo",staticStyle:{"padding-right":"20px","overflow":"hidden","text-overflow":"ellipsis"},on:{"open":_vm.open}},_vm._l((_vm.navList),function(item,index){return _c('el-submenu',{key:index,staticClass:"bgc",attrs:{"index":index}},[_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(item.name))])]),_vm._l((item.children),function(item1,index1){return _c('el-menu-item',{key:item1.id,staticStyle:{"width":"230px","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"index":index1}},[_c('div',{on:{"click":function($event){return _vm.nav_current(index1)}}},[_vm._v(" "+_vm._s(item1.name)+" ")])])})],2)}),1)],1),_c('div',{staticClass:"content_right"},[_c('div',[(
              _vm.navList[_vm.current] &&
                _vm.navList[_vm.current].children &&
                _vm.navList[_vm.current].children[_vm.current_index] &&
                _vm.navList[_vm.current].children[_vm.current_index] &&
                _vm.navList[_vm.current].children[_vm.current_index].type
            )?_c('div',[_c('p',{staticStyle:{"text-align":"center","color":"#666666","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.content.name)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.content.content)}})]):_c('iframe',{staticStyle:{"width":"1100px","height":"1000px"},attrs:{"src":_vm.a_src,"frameborder":"0"}})])]),_c('basecopyRight')],1),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',[_vm._v("请输入验证码")]),_c('el-input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.verification),callback:function ($$v) {_vm.verification=$$v},expression:"verification"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确 定")])],1)],1)],1):_c('div',{staticClass:"school"},[_c('div',{staticClass:"mob"},[_c('div',{staticClass:"head"},[_c('baseMobHeadTwo',{attrs:{"dataList":_vm.dataList,"banner":_vm.banner},on:{"mobopen":_vm.mobopen}})],1),_c('div',{staticClass:"contnet"},[_c('div',[_c('van-collapse',{attrs:{"accordion":""},on:{"change":_vm.open},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.navList),function(item,index){return _c('van-collapse-item',{key:index,attrs:{"title":item.name,"name":index}},_vm._l((item.children),function(item1,index1){return _c('p',{key:item1 + index1,on:{"click":function($event){return _vm.nav_current(index1)}}},[_vm._v(" "+_vm._s(item1.name)+" ")])}),0)}),1)],1),(
            _vm.navList[_vm.current] &&
              _vm.navList[_vm.current].children &&
              _vm.navList[_vm.current].children[_vm.current_index] &&
              _vm.navList[_vm.current].children[_vm.current_index] &&
              _vm.navList[_vm.current].children[_vm.current_index].type
          )?_c('div',[_c('p',{staticStyle:{"text-align":"center","color":"#666666","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.content.name)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.content.content)}})]):_c('iframe',{staticStyle:{"width":"100%","height":"500px"},attrs:{"src":_vm.a_src,"frameborder":"0"}})]),_c('div',{staticClass:"foot"},[_c('basecopyRight')],1)]),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.dialogVisible,"width":"70%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',[_vm._v("请输入验证码")]),_c('el-input',{attrs:{"placeholder":"请输入内容"},model:{value:(_vm.verification),callback:function ($$v) {_vm.verification=$$v},expression:"verification"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确 定")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }