<template>
  <div>
    <div class="school" v-if="$store.state.pc">
      <div class="head">
        <baseheadTwo
          :dataList="dataList.modular"
          @tabs="tabs"
          :banner="banner"
          :title="$route.query.name"
          :currentIndex="currentIndex"
        ></baseheadTwo>
      </div>
      <div class="content">
        <div class="shade" v-if="dialogVisible"></div>
        <div class="content_left">
          <el-menu class="el-menu-vertical-demo" @open="open" style="
                      padding-right:20px
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    ">
            <el-submenu
              :index="index"
              class="bgc"
              v-for="(item, index) in navList"
              :key="index"
            >
              <template slot="title">
                <span>{{ item.name }}</span
                >
              </template>

              <el-menu-item
                style="
                      width:230px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                :index="index1"
                v-for="(item1, index1) in item.children"
                :key="item1.id"
                ><div @click="nav_current(index1)">
                  {{ item1.name }}
                </div></el-menu-item
              >
            </el-submenu>
          </el-menu>
        </div>
        <div class="content_right">
          <div>
            <div
              v-if="
                navList[current] &&
                  navList[current].children &&
                  navList[current].children[current_index] &&
                  navList[current].children[current_index] &&
                  navList[current].children[current_index].type
              "
            >
              <p
                style="text-align: center;
                        color: #666666;
                        font-size: 18px;"
              >
                {{ content.name }}
              </p>
              <p v-html="content.content"></p>
            </div>
            <iframe
              :src="a_src"
              frameborder="0"
              style="width:1100px;height:1000px"
              v-else
            >
            </iframe>
          </div>
        </div>
        <basecopyRight></basecopyRight>
      </div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>请输入验证码</span>
        <el-input v-model="verification" placeholder="请输入内容"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="school" v-else>
      <div class="mob">
        <div class="head">
          <baseMobHeadTwo
            :dataList="dataList"
            :banner="banner"
            @mobopen="mobopen"
          ></baseMobHeadTwo>
        </div>
        <div class="contnet">
          <div>
            <van-collapse v-model="activeName" accordion @change="open">
              <van-collapse-item
                :title="item.name"
                :name="index"
                v-for="(item, index) in navList"
                :key="index"
              >
                <p
                  v-for="(item1, index1) in item.children"
                  :key="item1 + index1"
                  @click="nav_current(index1)"
                >
                  {{ item1.name }}
                </p>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div
            v-if="
              navList[current] &&
                navList[current].children &&
                navList[current].children[current_index] &&
                navList[current].children[current_index] &&
                navList[current].children[current_index].type
            "
          >
            <p
              style="text-align: center;
                        color: #666666;
                        font-size: 18px;"
            >
              {{ content.name }}
            </p>
            <p v-html="content.content"></p>
          </div>
          <iframe
            :src="a_src"
            frameborder="0"
            style="width:100%;height:500px"
            v-else
          >
          </iframe>
        </div>
        <div class="foot">
          <basecopyRight></basecopyRight>
        </div>
      </div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
        <span>请输入验证码</span>
        <el-input v-model="verification" placeholder="请输入内容"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  banner_list,
  index_list,
  twice_list,
  check,
  twice_chioce,
} from "../../api/api";
import baseheadTwo from "../../components/Public/BaseheadTwo/baseheadTwo.vue";
import baseMobHeadTwo from "../../components/Public/baseMobHeadTwo/baseMobHeadTwo.vue";
import basecopyRight from "../../components/Public/BasecopyRight/basecopyRight.vue";
export default {
  components: {
    baseheadTwo,
    baseMobHeadTwo,
    basecopyRight,
  },
  created() {
    this.getData();
  },
  updated() {},
  data() {
    return {
      activeName: [1, 2, 3],
      navList: "",
      dataList: "",
      banner: [],
      themeOther: JSON.parse(localStorage.getItem("themeOther")), //页面数据
      themeData: JSON.parse(localStorage.getItem("themeOther")), //页面数据
      currentIndex: 1, //nav下表
      dialogVisible: false, //验证码弹窗
      verification: "", //验证码文本框
      current: "", //1级index
      current_index: "", //2级index
      a_src: "", //文章src
      file_id: "", //文章id
      content: "", //富文本内容
    };
  },
  methods: {
    // 下拉内容页二级
    nav_current(index) {
      this.current_index = index;
      this.file_id = this.navList[this.current].children[this.current_index].id;

      if (
        this.navList &&
        this.navList[this.current] &&
        this.navList[this.current].children &&
        this.navList[this.current].children[this.current_index] &&
        this.navList[this.current].children[this.current_index].pwd_status
      ) {
        this.dialogVisible = true;
        console.log("加密");
      } else {
        if (this.navList[this.current].children[this.current_index].type) {
          twice_chioce({ file_id: this.file_id }).then((res) => {
            this.content = res.data.data;
          });
        } else {
          check({ password: this.verification, file_id: this.file_id }).then(
            (res) => {
              this.a_src = "";
              if (res.data.code == 200) {
                this.a_src = res.data.url;
                if(this.$store.state.mob){
                localStorage.setItem("preview_pdf", res.data.url);
                this.$router.push({path:"/pdf"})
                }
              } else {
                this.$message.error("输入错误");
              }
              this.dialogVisible = false;
              this.verification = "";
              this.file_id = "";
            }
          );
        }
      }
    },
    // 验证码验证
    confirm() {
      console.log("触发验证");
      console.log(this.navList[this.current].children[this.current_index].type);
      if (this.navList[this.current].children[this.current_index].type) {
        console.log(123);
        twice_chioce({ file_id: this.file_id }).then((res) => {
          console.log("加密content", res);
          this.content = res.data.data;
          this.dialogVisible = false;
          this.verification = "";
        });
      } else {
        check({ password: this.verification, file_id: this.file_id }).then(
          (res) => {
            this.a_src = "";
            if (res.data.code == 200) {
              this.a_src = res.data.url;
              localStorage.setItem("preview_pdf", res.data.url);
                this.$router.push({path:"/pdf"})
            } else {
              this.$message.error("输入错误");
            }
            this.dialogVisible = false;
            this.verification = "";
          }
        );
      }
    },
    // 下拉内容页一级
    open(index) {
      this.current = index;
    },
    // 导航tabs切换
    tabs(value, item) {
      this.currentIndex = value;
      console.log(item, value);
      if (value === 0) {
        this.$router.go(-1);
      } else {
        twice_list({
          project_id: item.project_id,
          modular_id: item.id,
          name: this.$route.query.name,
        }).then((res) => {
          if (res.data.code === 200) {
            this.navList = res.data.data;
          } else {
            this.$message.error("暂无信息");
            return;
          }
        });
      }
    },
    // 基本数据
    getData() {
      banner_list({ zl_url: this.$store.state.roleSchool2 }).then((res) => {
        this.banner = res.data.data;
        console.log(banner);
      }),
        index_list({ project_id: this.$route.query.project_id }).then((res) => {
          res.data.data.modular = [{ name: "首页" }, ...res.data.data.modular];
          this.dataList = res.data.data;
          console.log(this.dataList);
        });
      twice_list({ modular_id: this.$route.query.modular_id }).then((res) => {
        this.navList = res.data.data;
        console.log(this.navList);
      });
    },
    // 手机端切换nav
    mobopen(value) {
      this.currentIndex = value - 1;
      if (this.currentIndex == 0) {
        this.$router.push({ path: `/${this.$store.state.roleSchool2}` });
      } else {
        console.log(this.dataList.modular[this.currentIndex]);
        twice_list({
          project_id: this.dataList.modular[this.currentIndex].project_id,
          modular_id: this.dataList.modular[this.currentIndex].id,
        }).then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.navList = res.data.data;
          } else {
            this.$message.error("暂无信息");
            return;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/default/default.scss";
.pc {
  .school {
    .content {
      padding-top: 30px;
      width: 1440px;
      margin: 0 auto;
      position: relative;
      .shade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 550px;
        background: white;
      }
      .content_left {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-height: 500px;
        width: 260px;
        height: 100%;
        float: left;
        margin-right: 20px;
        background: white;
        /deep/ .el-menu-vertical-demo {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .el-submenu__title {
            span {
              width: 100%;
              overflow: hidden;
              width: 250px!important;
              height: 30px;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .content_right {
        min-height: 500px;
        width: 1150px;
        float: left;
        background: white;
      }
    }
  }
}
.mob {
  position: relative;
  .head {
  }
  .contnet {
    margin-top: 20px;
    min-height: 400px;
  }
  .foot {
  }
}
</style>
